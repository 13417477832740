import * as Yup from "yup";

const validEmailMsg = "A valid email is required";
const requiredFieldMsg = "This field is required";

export const newEmailValidation = Yup.string()
  .email(validEmailMsg)
  .max(75, "Emails can only be 75 characters long")
  .required(requiredFieldMsg);

export const existingEmailValidation = Yup.string().email(validEmailMsg).required(requiredFieldMsg);

export const newNameValidation = Yup.string()
  .max(30, "Names can only be 30 characters long")
  .required(requiredFieldMsg);

export const nameValidation = Yup.string()
  .max(30, "Names can only be 30 characters long")
  .required(requiredFieldMsg);

export const existingPasswordValidation = Yup.string().required(requiredFieldMsg);

export const newPasswordValidation = Yup.string()
  .min(8, "Your password must be at least 8 characters")
  .required(requiredFieldMsg);

export const levelValidation = Yup.string().required(requiredFieldMsg);

// only require grad year if user is a student
// if teacher/professor/other, can skip
export const hsGraduationyearValidation = Yup.number().when("level", {
  is: (level) => level?.toLowerCase()?.includes("student"),
  then: Yup.number().required(requiredFieldMsg),
  otherwise: Yup.number().nullable(),
});

export const subjectsValidation = Yup.array()
  .of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
      label: Yup.string(),
      emoji: Yup.string(),
    })
  )
  .min(1, "You must select at least one option")
  .required(requiredFieldMsg);

export const subjectsFromUrlValidation = Yup.array()
  .of(
    Yup.object().shape({
      id: Yup.string(),
    })
  )
  .min(1, "You must select at least one option")
  .required(requiredFieldMsg);

export const subjectsValidationNotRequired = Yup.array()
  .of(
    Yup.object().shape({
      id: Yup.string(),
      name: Yup.string(),
      label: Yup.string(),
      emoji: Yup.string(),
    })
  )
  .nullable()
  .default([]);
