import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { useSpecificTheme } from "@/hooks/useSpecificTheme";
import { useField } from "formik";

const TextField = ({ InputProps, ...props }: TextFieldProps): JSX.Element => {
  const theme = useSpecificTheme();
  const [field, meta] = useField(props?.name);
  const { sx, ...rest } = InputProps || {};

  const hasError = Boolean(meta.touched && meta.error);

  return (
    <MuiTextField
      error={hasError}
      helperText={hasError ? meta.error : ""}
      InputProps={{
        sx: {
          background: theme.palette.background.paper,
          typography: "body2",
          ...sx,
        },
        ...rest,
      }}
      fullWidth
      {...field}
      {...props}
    />
  );
};

export default TextField;
