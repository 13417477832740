import { useSelector } from "react-redux";
import { Theme } from "@mui/system";
import libraryLight, { libraryDark } from "@/styles/libraryThemeRefresh/libraryTheme";

export enum ThemeMode {
  LIGHT = "light",
  DARK = "dark",
}

export const defaultThemeMap = {
  [ThemeMode.LIGHT]: libraryLight,
  [ThemeMode.DARK]: libraryDark,
};

export type ThemeMap = {
  [ThemeMode.LIGHT]: Theme;
  [ThemeMode.DARK]: Theme;
};

// TODO: should this default or fail?
export const useSpecificTheme = (themeMap: ThemeMap = defaultThemeMap): Theme => {
  const themeMode = useSelector((state: { libraryThemeMode: ThemeMode }) => state.libraryThemeMode);

  return themeMap[themeMode] || themeMap[ThemeMode.LIGHT];
};
