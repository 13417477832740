import { ButtonProps as MuiButtonProps } from "@mui/material";
import MuiButton from "@mui/material/Button";
import React from "react";

export type ButtonProps = MuiButtonProps & {
  component?: string;
  variant:
    | "primary"
    | "secondary"
    | "contained"
    | "error"
    | "textHighlighted"
    | "textPrimary"
    | "textSecondary"
    | "text";
};

export const Button: React.FC<ButtonProps> = ({ ...props }) => {
  return <MuiButton {...props} />;
};
