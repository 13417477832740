import { ReadonlyURLSearchParams, usePathname, useRouter, useSearchParams } from "next/navigation";

interface IQueryParamUpdate {
  paramName: string;
  paramValue: string | string[];
}

// function isReadOnlySearchParams(value: unknown): value is ReadonlyURLSearchParams {
//   return (
//     value instanceof URLSearchParams ||
//     (typeof value === "object" &&
//       value !== null &&
//       "get" in value &&
//       "getAll" in value &&
//       "has" in value &&
//       "entries" in value)
//   );
// }

// /* eslint-disable no-restricted-syntax */
// function searchParamsToObject(searchParams: ReadonlyURLSearchParams): Record<string, string | string[]> {
//   const result: Record<string, string | string[]> = {};
//   for (const [key, value] of searchParams.entries()) {
//     // If key already exists, convert to array or append to existing array
//     if (key in result) {
//       if (Array.isArray(result[key])) {
//         (result[key] as string[]).push(value);
//       } else {
//         result[key] = [result[key] as string, value];
//       }
//     } else {
//       result[key] = value;
//     }
//   }
//   return result;
// }
/* eslint-enable no-restricted-syntax */

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useUpdateQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _pathname?: string | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  queryParams?: ReadonlyURLSearchParams | Record<string, string | string[] | undefined> | null | undefined
) => {
  const router = useRouter();
  // the prop drilling here is bad, partial (re)deprecation lets goooo
  const searchParams = useSearchParams();
  const pathname = usePathname();
  // eslint-disable-next-line no-param-reassign
  // queryParams = isReadOnlySearchParams(queryParams) ? searchParamsToObject(queryParams) : queryParams;
  const updateQueryParams = ({ newParams }: { newParams: IQueryParamUpdate[] }): void => {
    const urlParams = new URLSearchParams(searchParams?.toString());
    // back compat to pages router - searchParams will include route params.
    ["communitySlug", "subjectSlug", "slug"].forEach((param) => urlParams?.delete(param));
    newParams.forEach(({ paramName, paramValue }) => {
      if (Array.isArray(paramValue)) paramValue.forEach((value) => urlParams.append(paramName, value));
      else urlParams.set(paramName, paramValue);
    });

    const paramString = urlParams.toString();

    const newUrl = `${pathname}?${paramString}`;
    router.replace(newUrl);
  };

  const removeQueryParamFromUrl = (url: string, params): string => {
    // TODO: need to make this a consistent form or define allowed url types etc.
    // const rawUrl = new URL(url);
    const urlSearchParams = new URLSearchParams(searchParams?.toString());
    params.forEach((param) => urlSearchParams.delete(param));
    return `${pathname}?${urlSearchParams.toString()}`.replace(/\?$/, "");
    // const urlParts = url.split("?");
    // if (urlParts.length >= 2) {
    //   const urlParams = urlParts[1].split("&");
    //   const newParams = urlParams.filter((queryParam) => {
    //     const [key] = queryParam.split("=");
    //     return !params.includes(key);
    //   });
    //   const newUrl = urlParts[0] + (newParams.length > 0 ? `?${newParams.join("&")}` : "");
    //   return newUrl;
    // }
    // return url;
  };

  return { updateQueryParams, removeQueryParamFromUrl };
};

export default useUpdateQueryParams;
